@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/breakpoints';

.privacyPolicyContainer {
    background: var(--brand-offwhite);
}

.contentContainer {
    @include container();
    max-width: 600px;
    width: 80%;
    @media (max-width: $fablet) {
        max-width: 280px;
    }
    padding: 0 2rem 8rem;

    h3 {
        text-transform: lowercase;
        margin: 3rem auto 1rem;
    }
}

.headlineContainer {
    @include container();
    max-width: 600px;
    width: 80%;
    @media (max-width: $fablet) {
        max-width: 280px;
    }
    padding: 10rem 2rem 2rem;
    font-size: 2.2rem;
    @include page-heading();
}