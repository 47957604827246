@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/theme';
@import '../../global/styles/foundation/breakpoints';

.clientListContainer {
    // background: darken($brand-black, 10%);
    background: var(--brand-black);
    padding: 6rem 4rem;
    position: relative;

    @media (max-width: $tablet) {
        padding: 3rem 2rem;
    }

    h2 {
        color: $brand-offwhite;
        text-align: center;
        @include page-heading(false, clamp(22px, 2vw, 32px));
        padding: 2rem 0;
        font-weight: 100;

        @media (max-width: $tablet) {
            font-size: 24px;
            line-height: 1.4;
        }
    }

    .galleryContainer {
        @include container();

        @media (max-width: $tablet) {
            padding: 0;
        }

        & > div {
            @include grid(6);
            text-align: center;
            align-items: center;
            grid-gap: 0.5rem;
            @media (max-width: $desktop-small) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 1rem 2rem;
            }
            @media (max-width: $tablet) {
                // grid-template-columns: repeat(2, 1fr);
            }
            
            figure {
                pointer-events: none;

                img {
                    height: auto;
                    width: 100%;
                    max-width: 140px !important;
                    opacity: 0.7;
                }
            }
            
            figcaption {
                display: none;
            }
        }
    }
}

.meta {
    @include meta();
    color: rgba($white, 1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .clientListContainer {
        .galleryContainer > div {
            display: -ms-flexbox;
            flex-wrap: wrap;

            figure {
                margin: 1rem;
            }
        }
    }
}