@import '../../global/styles/foundation/breakpoints';

.notificationBar {
    @media(max-width: $desktop-small) {
        display: none;
    }
    background: var(--bar-color);
    text-align: center;
    padding: 0.4rem;

    .barCopy {
        font-size: 0.9rem;
        max-width: 90%;
        margin: 0 auto;
        font-family: var(--serif);

        p {
            display: inline;
        }
    }

    .link, .barCopy {
        display: inline-block;
        color: var(--bar-font-color);
    }

    .link {
        text-decoration: underline;
        font-style: italic;
        margin: 0 0.2rem;
    }

    .dismiss {
        display: block;
        position: absolute;
        right: 1rem;
        top: 0.3rem;
        height: 20px;
        width: 20px;
        cursor: pointer;

        span {
            position: absolute;
            width: 100%;
            height: 2px;
            background: var(--bar-font-color);
            transform-origin: 50%;
            left: 0;
            right: 0;
            top: 50%;

            &:first-of-type {
                transform: rotate(45deg);
            }

            &:last-of-type {
                transform: rotate(-45deg);
            }
        }

    }
}

