// base colors
$default-black: #131115;
$default-white: #fff;

// brand colors
$offwhite: #f7f7f7;
// $black: #000000;
$black: #131115;
$yellow: #ffb100;
$coral: #f26555;
$teal: #017a84;
$green: #273f2e;
$brown: #38322f;

:root {
    --default-black: #131115;
    --default-white: #fff;

    // brand colors
    --offwhite: #f7f7f7;
    --black: #131115;
    --yellow: #ffb100;
    --coral: #f26555;
    --teal: #017a84;
}