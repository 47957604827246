@import '../../global/styles/utils/mixins';

.threeKings {
    &:empty {
        min-height: 80vh;
        @include loading-spinner-base();
        @include rotate-animation();
    }

    background: var(--brand-offwhite);
    padding: var(--section-padding);
    position: relative;

    @media (max-width: $desktop-small) {
        padding: 6rem 2rem;
    }
    @media (max-width: $fablet) {
        padding: 6rem 0;
    }
    
    h2 {
        @include page-heading(false, clamp(22px, 2.8vw, 38px));
        max-width: 500px;
        line-height: 1.2;
        
        span {
            color: var(--brand-one);
        }
    }
}

.meta {
   @include meta();
}

.container {
    @include container();
}

.gridContainer {
    @include grid(4);
    grid-gap: 5rem;

    @media (max-width: $desktop-medium) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
    }

    h3 {
        text-transform: lowercase;
        font-family: var(--serif);
        font-size: clamp(20px, 2vw, 24px);
        @media (max-width: $fablet) {
            font-size: 1.2rem;
        }
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        position: relative;
    
        &:after {
            @include psuedo-boiler();
            width: 2rem;
            height: 1px;
            left: 0;
            bottom: 0;
            background: var(--black);
        }
    }
}


.squiggle {
    @include squiggle();
}

.panelCTA {
    @include btn-primary(var(--black), var(--brand-offwhite));
    margin-top: 5rem;
}


// ie fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .threeKings {
        padding: 80px 1rem;
    }
    .gridContainer > div {
        display: block;
        width: 33%;
        float: left;

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}