
@import '../../global/styles/utils/mixins';

.getInTouchContainer {
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--brand-offwhite);

    .contactRight {
        background: url(../../img/connect-bg-cards.jpg)center center no-repeat;
        height: 100%;
        background-size: cover;

        @media (max-width: $desktop-small) {
            height: 20vh;
        }
    }

    @media (max-width: $desktop-small) {
        display: block;
    }

}

.formComponent {
    min-width: 600px;
    
    padding: 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: $fablet) {
        min-width: 0;
        width: 95%;
        padding: 2rem 1rem;
    }
}

.title {
    @include page-heading(false, clamp(28px, 3vw, 42px));
    margin-bottom: 3rem;
    margin-top: 1rem;
    text-align: center;
}

.disclaimer {
    font-size: 0.7rem;
    font-family: var(--serif);
    margin: 1rem auto;
    text-align: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .getInTouchContainer {
        padding-top: 120px;
    }
}

.socialLinks {
    text-align: center;
}