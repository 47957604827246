@import '../../global/styles/foundation/breakpoints';

.formContainer {
    padding: 1rem 0;

    h2 {
        font-size: 14px;
        font-family: var(--sans-serif);
        margin-bottom: 1rem;
        letter-spacing: 0.3px;
    }

    form {
        margin: 0 auto;
        justify-content: center;

        display: flex;
        .form {
            input {
                padding: 0.8rem;
                width: 220px;
                border: none;
                outline: none;
                border: 1px solid var(--brand-black);
            }
        }
        .hiddenInput {
            left: -5000px;
        }
    }
}

.formLeft {
    
}

.formRight {
    .submitButton { 
        height: 100%;
        border: none;
        padding: 0 1rem;
        border: 1px solid var(--brand-black);
        outline: none;
        font-size: 14px;
        font-family: var(--sans-serif);
        letter-spacing: 0.3px;
        color: var(--brand-black);
        background: white;
        border-left: none;
        transition: all 0.15s ease-in-out;

        @media (min-width: $tablet) {
            &:hover {
                background: var(--brand-black);
                color: var(--brand-offwhite);
            }
        }
    }
}