@import '../../global/styles/utils/mixins';

.formContainer {
    --componentWidth: 500px;
    display: flex;
    align-items: center;
    transition: 0.15s ease-in-out;

    form {
        width: 100%;
        flex-grow: 1;
        max-width: var(--componentWidth);
        margin: 0 auto;
    }

    input, select, textarea {
        padding: 1rem;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid var(--brand-black);
        margin:0 auto 2rem auto;
        background: var(--brand-offwhite);
        font-size: 1rem;
        font-family: var(--sans-serif);
        color: var(--brand-black);
        transition: all 0.2s ease-in-out;

        &:focus {
            box-shadow: inset 0 0 0 2px var(--brand-black);
        }

        @media (max-width: $tablet) {
            font-size: 0.9rem;
            font-weight: 100;
        }
    }

}

input[type="submit"] {
    background: var(--brand-black);
    color: var(--brand-offwhite);
    font-size: 1.2rem;
    cursor: pointer;
    border-bottom: none;

    @media (max-width: $tablet) {
        font-weight: bold;
    }
}

textarea {
    min-height: 150px;
}

.labelPrompt, 
label span {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    display: block;
    text-align: left;
    font-family: var(--sans-serif);
}

.loading {
    opacity: 0.3;
}

.dropdownOpen {
    display: block;
}

.dropdownClose {
    display: none;
    overflow: hidden;
}

.dropdownContainer {
    margin-top: 100px;
    max-width: var(--componentWidth);
    margin: 0 auto 2rem auto;
    position: relative;
    z-index: 0;
    background: var(--brand-offwhite);
    padding: 1rem;
    text-align: left;
    font-size: 1rem;
    color: var(--brand-black);
    border: 1px solid var(--brand-black);

    &:focus {
        box-shadow: inset 0 0 0 2px var(--brand-black);
    }

    @media (max-width: $tablet) {
        font-size: 0.9rem;
    }

    .dropdownCaret {
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        right: 1.5rem;
        top: 0;
        bottom: 0;
        margin: auto;

        @media (max-width: $tablet) {
            width: 1rem;
            height: 1rem;
            right: 1rem;
        }
    }

    ul {
        position: absolute;
        width: 100%;
        z-index: 0;
        top: 3.4rem;
        left: 0;

        @media (max-width: $tablet) {
            top: 2.2rem;
        }
    }

    .dropdownOpen {
        box-shadow: 1px 20px 40px 1px rgba(black, 0.2);
    }

    select {
        display: none;
    }
    
    li {
        background: white;
        padding: 1.2rem 0.8rem;
        text-align: left;
        margin: 0 auto;
        border: 1px solid var(--brand-offwhite);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.1s ease-in-out;

        @media(min-width: $desktop-small) {
            &:hover {
                background: #f9f9f9;
            }
        }
    }

    span {
        pointer-events: none;
        cursor: pointer;
    }
}

.submitSuccessContainer {
    margin: 1rem auto;
    font-family: var(--serif);

    h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    a {
        @include btn-primary();
    }
}