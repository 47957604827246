@import '../../global/styles/utils/mixins';
.marquee {
    position: relative;
    overflow: hidden;
    margin-bottom: 1px;
    --offset: 20vw;
    --move-initial: 0%;
    --move-final: -3748px;

    ul {
        display: flex;
        width: 9000px;
        border-bottom: 1px solid var(--brand-black) !important;
        border-top: 1px solid var(--brand-black);
        li {
            padding: 1rem 4rem;
            font-family: var(--serif);
            font-weight: 100;

            &:before {
                content: '+';
                margin-right: 9rem;
            }
        }
    }
}

.marqueeInner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);    
    animation: marquee 60s linear infinite;
}

.marquee li {
    padding: 0 2vw;
}

@media (max-width: $desktop-small) {
    .marquee {
        ul {
            animation: marquee 120s linear infinite;
            li {
                padding: 1rem 1.2rem;
                font-size: 14px;
            }
        }
    }
    .marquee ul li:before {
        margin-right: 2rem;
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}

