@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/theme';
@import '../../global/styles/foundation/breakpoints';


.aboutContainer {
    @include grid(2);
    background: var(--brand-offwhite);
    align-items: stretch;

    @media (max-width: $desktop-medium) {
        display: block;
    }
}

.imageContainer {
    background: $offwhite;
    grid-column: span 1;
    overflow: hidden;
    position: relative;
    height: 100%;
    
    @media (min-width: $desktop-medium) {
        min-height: 100vh;
    }

    @media (max-width: $desktop-medium) {
       max-height: 60vh;
    }

    img {
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        object-fit: cover;

        @media (max-width: $desktop-medium) {
            position: relative;
            top: 0%;
            left: 0%;
            transform: translate(0,0);
            width: 100%;
            height: auto;
        }
    }
}

.contentContainer {
    grid-column: span 1;
    align-self: center;
    margin: 6rem;
    margin: 12vh 8vw;
    max-width: 600px;

    @media (max-width: $desktop-medium) {
        margin: 4rem 2rem 0;
        padding-bottom: 4rem;
        max-width: 800px;
    }
    

    h1 {
        @include page-heading(false, clamp(22px, 3vw, 48px));
    }
}

.content {

    p:first-of-type {
        font-family: var(--serif);
        font-size: 1.2rem;
    }
}

.button {
    @include btn-primary($black, $white, $black);
    text-align: center;
    @media (max-width: $tablet) {
        margin-top: 1rem;
    }
}

.squiggle {
    @include squiggle();
    margin-bottom: 2rem;
}

.aboutCTA {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    @media (max-width: $tablet) {
        display: block;
        text-align: center;
    }
}

.socialLinks {
    margin-top: 1rem;

    a {
        margin-right: 1rem;
        transition: all 0.1s ease;

        &:hover {
            @media (min-width: $desktop-small) {
                color: var(--brand-one);
            }
        }
    }
}

// ie fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .imageContainer {
        width: 100%;
        min-height: 50vh;
        height: 50vh;
        img {
            width: 100%;
            height: auto;
        }
    }
    .contentContainer {
        margin: 100px auto;
    }
}