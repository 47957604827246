@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/theme';
@import '../../global/styles/foundation/breakpoints';


.footerContainer {
    padding: 2rem 1rem;
}

.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 0.8rem;
    grid-gap: 20px;
    
    @media(max-width: $tablet) {
        display: block;
    }

    ul {
        margin-top: 1rem;
        padding-left: 0.2rem;
        @media(max-width: $desktop-small) {
            display: none;
        }

        li a {
            font-size: 0.7rem;
            display: block;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }
}

.footerSocials {
    font-size: 18px;
    text-align: center;
}

.logo {
    max-width: 300px;
    margin-top: 0.5rem;
    text-align: left;

    @media(max-width: $tablet) {
        text-align: center;
        margin: 0 auto;
    }

    figcaption {
        margin-top: 0.6rem;
        font-family: var(--serif);
        // font-style: italic;
        text-transform: lowercase;

        a {
            text-decoration: underline;
        }
    }

    @media(max-width: $fablet) {
        max-width: 100%;
    }

    img {
        max-width: 240px;
    }

}

.copyrightContainer {
    font-family: var(--serif);
    // font-style: italic;
    font-weight: 100;
    text-transform: lowercase;
    text-align: right;
    width: 100%;

    @media(max-width: $tablet) {
        text-align: center;
    }

    span, a {
        color: $brand-black;
        display: block;
    }
    a {
        text-decoration: underline;
    }
}

.social a {
    font-size: 1.0rem;
    color: var(--brand-one);
    padding: 0.6rem;
    margin: 0.3rem;
}

// ie fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .footerContainer nav {
        display: none;
    }
}


aside {
    padding: 1rem 0;
    color: black;

    h2 {
        font-size: 14px;
        font-family: var(--sans-serif);
        margin-bottom: 1rem;
    }

    > div {
        // @include container();
        // display: flex;
    }

    form {
        margin: 0 auto;
        justify-content: center;

        display: flex;
        .form {
            input {
                padding: 0.8rem;
                width: 220px;
                border: none;
                outline: none;
                border: 1px solid var(--brand-black);
                color: red;
            }
            .submitButton {
                @include btn-primary();
            }
        }
        .hiddenInput {
            left: -5000px;
        }
    }
}

.formLeft {
    
}

.formRight {
    .submitButton { 
        height: 100%;
        border: none;
        padding: 0 1rem;
        border: 1px solid var(--brand-black);
        outline: none;
        font-size: 14px;
        font-family: var(--sans-serif);
        letter-spacing: 0.3px;
        color: var(--brand-black);
        background: white;
        border-left: none;
    }
}