@import '../../global/styles/foundation/theme';
@import '../../global/styles/utils/mixins';
$mainheightbreakpoint: 1200px;
$widthbreakpoint: 1700px;

.heroContainer {
    height: 800px;
    display: flex;
    align-items: center;
    // background: #ce7a02;
    background: #171717;
    position: relative;
    background-size: 100%;
    transition: background-position 0.2s ease;
    position: relative;

    @media (max-width: $desktop-large) {
        height: 760px;
    }

    @media (max-width: $tablet) {
        height: 650px;
    }        

    .heroImageContainer {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;

        img {
            height: 100%;
            right: 0;
            position: absolute;
            @include transition(0.1s);

            @media(max-width: $widthbreakpoint) { 
                right: -200px;
            }

            @media (max-width: $desktop-medium) {
                right: 0;
            }

            @media (max-width: $tablet) {
                right: -120px;
            }

            @media (max-width: $fablet) {
                right: -220px;
            }
        }

        .partialiPad {
            left: 0;
            max-width: 320px;
            height: auto;
            bottom: 0;

            @media (max-width: 1800px) {
                left: -120px;
            }
            @media (max-width: 1000px) {
                display: none;
            }
        }
    }

    .ipadContainer {
        position: absolute;
        z-index: 10;
        right: 170px;
        top: 130px;
        @include transition(0.1s);

        @media(max-width: $widthbreakpoint) { 
            right: -40px;
            top: 120px;
        }

        @media(max-width: $desktop-large) {
            right: -20px;
        }
        
        @media (max-width: $desktop-medium) {
            display: none;
        }

        img {
            height: 880px;

            @media(max-width: $desktop-large) {
                height: 780px;
            }
            
        }

        .sliderContainer {
            // display: none;
            $width: 475px;
            $widthbreakpointimg: 420px;
            position: absolute;
            top: 90px;
            height: 632px;
            left: 142px;
            width: $width;
            border-radius: 8px;
            overflow: hidden;
            transform: rotate(-24.4deg);

            @media(max-width: $desktop-large) {
                width: $widthbreakpointimg;
                height: 560px;
                top: 80px;
                left: 125px;
            }

            .imageContainer {
                width: 300vh;
                animation: alls 12s ease-in-out infinite;
                animation-delay: 0.4s;

                @media(max-width: $desktop-large) {
                    animation: sliderbreakpoint 12s ease-in-out infinite;
                }
                
                div {
                    display: inline;
                    overflow: hidden;
    
                    img {
                        opacity: 0.95;
                        width: $width;
                        height: auto;

                        @media(max-width: $desktop-large) {
                            width: $widthbreakpointimg;
                        }
                    }
                }
            }

            @keyframes alls{
                0%{margin-left: 0px;}
                23%{margin-left: 0px;}
                29%{margin-left: -#{$width};}
                48%{margin-left: -#{$width};}
                54%{margin-left: calc(-1 * (#{$width} * 2)); }
                73%{margin-left: calc(-1 * (#{$width} * 2)); }
                79%{margin-left: calc(-1 * (#{$width} * 3)); }
                98%{margin-left: calc(-1 * (#{$width} * 3)); }
                100%{margin-left: 0px; }
            }

            @keyframes sliderbreakpoint {
                0%{margin-left: 0px;}
                23%{margin-left: 0px;}
                29%{margin-left: -#{$widthbreakpointimg};}
                48%{margin-left: -#{$widthbreakpointimg};}
                54%{margin-left: calc(-1 * (#{$widthbreakpointimg} * 2)); }
                73%{margin-left: calc(-1 * (#{$widthbreakpointimg} * 2)); }
                79%{margin-left: calc(-1 * (#{$widthbreakpointimg} * 3)); }
                98%{margin-left: calc(-1 * (#{$widthbreakpointimg} * 3)); }
                100%{margin-left: 0px; }
            }
        }
    }
}

.copyContainer {
    @include container();
    margin-top: 16rem;
    transition: max-width 0.1s ease-in-out;
    position: relative;
    z-index: 10;
    // padding-left: 4rem;

    @media (max-width: 2000px) {
        max-width: 1300px;
    }

    @media (max-width: 1600px) {
        max-width: 960px;
    }

    @media (max-width: $desktop-large) {
        max-width: 1100px;
        margin-top: 20rem;
    }

    @media (max-width: $desktop-medium) {
        max-width: 800px;
        margin-top: 14rem;
    }

    @media (max-width: $tablet) {
        max-width: 650px;
    }

    @media (max-width: $fablet) {
        max-width: 450px;
        margin-top: 10rem;
    }

    h1 {
        display: block;
        // font-size: 2.4rem;
        font-size: clamp(28px, 2.6vw, 60px);
        line-height: 1.2;
        color: #fff;
        font-weight: 400;

        @media (max-width: $desktop-large) {
            // font-size: 1.8rem;
        }

        @media only screen 
            and (device-width : 375px) 
            and (device-height : 812px) 
            and (-webkit-device-pixel-ratio : 3) { 
                // font-size: 1.2rem;
            }


        p {
            overflow: hidden;
            position: relative;
            margin-bottom: 0;

            span {
                transform: translateY(110%);
                display: block;
                transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;  
                opacity: 0;
            }

            strong {
                display: inline-block;
                color: white;
            }
        }
    }
}

.heroCTA {
    margin-top: 3rem;
    @include btn-primary(white, black, white);
    margin-bottom: 4rem;
    opacity: 0;
    transform: translateY(1.8rem);
    transition: transform 0.8s ease-in-out 1.4s, 
                opacity 0.8s ease-in-out 1.5s;
}

.heroContainer {
    .fadeIn {
        h1 {
            opacity: 1;

            @for $i from 1 through 4 {
                p:nth-child(#{$i}) span {
                  transition-delay: $i/4+s;
                }
            }            

            span {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .heroCTA {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .copyContainer {
        margin-top: 35vh;
    }
}