@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/breakpoints';

.container {
    @include container();
    @include grid();
        padding: 2rem;
}

.portfolioInfoPanel {
    padding: 6rem 0;
    @media (max-width: $fablet) {
        padding: 3rem 0;
    }

    .clientLogo {
        max-width: 320px;
        margin-bottom: 4rem;

        @media (max-width: $fablet) {
            max-width: 200px;
        }

        img {
            max-width: 100%;;
        }
    }

    h1 {
        
        font-size: clamp(32px, 2.4vw, 60px);
        font-size: 26px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 1rem;
        font-family: var(--sans-serif);
    }

    h2 {
        margin: 2rem 0;
        font-size: 1.2rem;
        letter-spacing: 0.5px;
    }

    .categoryContainer {
        margin: 4rem 0 1rem;
        padding:0;

        li {
            list-style: none;
            display: inline-block;
            font-size: 1.2rem;
            letter-spacing: 0.5px;
            font-family: var(--serif);
            font-weight: bold;
            margin-right: 0.2rem;

            &:not(:last-of-type):after {
                content: '';
                white-space: pre;
                content: ', ';
                margin-left: -0.1rem;
            }
        }
    }

    a {
        display: block;
    }

    ul {
        margin-left: 1.2rem;

        li {
            list-style: circle;
        }
    }    
}

.heroImageContainer {
    max-height: 65vh;
    margin-bottom: -1rem;
    overflow: hidden;

    img {
        max-width: 100%;
        width: 100%;
        height: 65vh;
        object-fit: cover;
        object-position: center;
    }
}

.label {
    @include label();
    margin-bottom: 1.4rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid white;
    

    &:not(:first-of-type) {
        margin-top: 4rem;
    }
}

.infoPanelContent {
    grid-column: 1/8;
    
    @media (max-width: $tablet) {
        grid-column: 1/-1;
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 2rem;

        @media (max-width: $tablet) {
            font-size: 16px;
        }
    }
}

.infoPanelAside {
    grid-column: 10/-1;
    line-height: 1.8;

    @media (max-width: $tablet) {
        margin-top: 2rem;
        grid-column: 1/-1;
    }

    .callout {
        font-size: 1.2rem;
        font-family: var(--serif);
        font-weight: 100;
    }

    a {
        text-decoration: underline;
    }

    .tags {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            text-transform: capitalize;
            margin: 0;
            margin-right: 0.2rem;
            font-family: var(--serif);
        }
        
        a {
            font-family: var(--serif);
            text-decoration: none;
            
            @media(min-width: $tablet) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.portfolioContent {
    
    img {
        max-width: 100%;
        height: auto;
        width: 100%;
    }
}