@import '../../global/styles/utils/mixins';

.mobileShow {
    display: none !important;
}

.headerBackground {
    background: var(--brand-offwhite);
    color: var(--brand-black);
}

.headerContainer {
    @include grid();
    @include container();
    position: relative;
    padding: 0;
}

.headerPanel {
    @include page-header-panel();
    padding-bottom: 6rem;
    grid-column: 1/7;

    @media(max-width: $desktop-large) {
        padding: 6rem 0;
        grid-column: 1 / 6;
    }

    p {
        line-height: 1.5;
    }

    @media(max-width: $desktop-small) {
        grid-column: 1/-1;
        padding-bottom: 2rem;
        padding: 10rem 0 2rem;
    }

    @media(max-width: $tablet) {
        padding-top: 6rem;
    }
}

.squiggle {
    @include squiggle();
    margin-bottom: 2rem;
}


.nav {
    grid-column: 10/-1;
    display: flex;
    justify-content: center;
    flex-flow: column;
    font-family: var(--serif);
    height: 100%;
    padding: 0 0 0 4rem;
    border-left: 1px solid var(--brand-black);

    @media(max-width: $desktop-large) {
        grid-column: 8 / -1;

    }


    @media(max-width: $desktop-small) {
        grid-column: 1/-1;
        border: none;
        padding: 0 0 4rem;
        text-align: center;
    }

    @media (max-width: $fablet) {
        text-align: left;
    }

    p {
        font-style: normal;
        text-transform: lowercase;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--brand-black);

        @media (max-width: $desktop-small) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    a {
        display: block;
        cursor: pointer;
        width: 100%;
        text-transform: lowercase;
        transition: all 0.1s ease-in-out;
        text-decoration: underline;
        color: var(--brand-black);

        @media(max-width: $desktop-small) {
            padding: 0.5rem;
        }

        @media(max-width: $tablet) {
            padding: 0 0 1rem 0;
        }

        &:before {
            content: attr(data-key);
            display: inline-block;
            margin-right: 0.4rem;

            @media(min-width: $desktop-small) {
                margin-right: -0.4rem;
                font-size: 2.2rem;
                line-height: 1.2;
                opacity: 0.08;
            }
        }

        @media(min-width: $tablet) {
            &:hover {
                color: var(--color);
            }
        }
    }
}

.container {
    @include grid();
    @include container();
    padding: 11rem 1rem;
    align-content: stretch;

    @media(max-width: $tablet) {
        padding: 8rem 1rem;
    }
}

.CTALink {
    margin-top: 3rem;
    @include btn-primary(var(--brand-black), var(--brand-offwhite), var(--brand-black));

    @media(max-width: $tablet) {
        text-align: center;
        display: block;
        margin: 4rem auto 0;
    }

    @media (max-width: $fablet) {
        width: 100%;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .serviceItemContainer {        
        &:nth-child(even) {
            background: #f7f7f7;
        }
    }
}


.newServicesContainer {
    background: var(--brand-offwhite);
    padding: var(--section-padding);
    border-top: 1px solid var(--brand-black);
    position: relative;

    @media (max-width: $tablet) {
        padding: 3rem 0;
    }

    svg {
        fill: var(--brand-black);
    }

    &:nth-child(even) {
        background: var(--brand-white);
    }

}

.container {
    @include grid();
    @include container();

    .left {
        grid-column: 1 / 7;

        h2 {
            @include page-heading(false, clamp(24px, 2.2vw, 38px));
            font-weight: 100;
            line-height: 1.2;
            padding-bottom: 3rem;
            margin-top: 1rem;
        }

        p {
            line-height: 1.5;
            margin-bottom: 24px;
        }

        .ctaButton {
            @include btn-primary(var(--brand-black), var(--brand-offwhite), var(--brand-offwhite));
            margin-top: 3rem;
        }
    }

    .right {
        grid-column: 9 / -1;
        padding-top: 40px;

        p {
            font-size: 1.2rem;
            font-family: var(--serif);
            margin-bottom: 2rem;
        }
        
        p.tagLine {
            font-size: 0.8rem;
            margin-top: 1rem;
            font-family: var(--sans-serif);
            text-align: right;
        }

        li {
            padding: 1.4rem 0;
            border-bottom: 1px solid var(--brand-black);
            &:first-of-type {
                border-top: 1px solid var(--brand-black);
            }
        }
    }

    @media(max-width: $desktop-medium) {
        .mobileShow {
            display: block !important;
        }
        .mobileHide {
            display: none !important;
        }
        .left, .right {
            grid-column: 1 / -1;
        }

        .CTALink {
            grid-column: 1 / -1;
            text-align: center;
        }
    }
}

.serviceMeta {
    display: flex;
    gap: 40px;
    margin-top: 3rem;

    div {
        display: flex;
        gap: 12px;
    }
}

.coreValues {
    background: var(--brand-black);
    color: var(--brand-offwhite);
    padding: var(--section-padding);

    @media (max-width: $tablet) {
        padding: 3rem 0;
    }
}

.coreValuesContainer {
    @include container();
    
}

.coreValuesHeader {
    h2 {
        @include page-heading(false, clamp(26px, 2.2vw, 38px));
        font-weight: 100;
        margin-bottom: 4rem;

        @media(max-width: $tablet) {
            margin-bottom: 3rem;;
        }
    }
}

.coreValueItems {
    @include grid();
    grid-gap: 8%;

    @media(max-width: $tablet) {
        display: block;
    }

    div {
        grid-column: span 4;
        @media(max-width: $tablet) {
            margin-bottom: 3rem;
        }

        h3 {
            font-size: clamp(20px, 1.2vw, 28px);
        }

        .squiggle {
            @include squiggle();
            margin: 2rem 0 1.5rem;

            @media(max-width: $tablet) {
                margin: 1rem 0;
            }
        }
        p {
            line-height: 1.5;
        }
    }
}

@media (max-width: $desktop-small) {
    .coreValuesHeader h2 {
        font-size: 26px;
    }
    .coreValueItems {
        div {
            grid-column: 1 / -1;
        }
    }
}





.serviceImage {
    grid-column: 9 / -1;
    max-height: 180px;
    background: var(--brand-black);
    overflow: hidden;
    margin-top: 3.5rem;

    img {
        max-height: 180px;
        width: 100%;
        object-fit: cover;
    }

}

