@import './global/styles/utils/index';
@import './global/styles/foundation/index';
@import './global/styles/utils/mixins';

#root {
    width: 100%;
    min-height: 100vh;
}

p.italics {
    font-size: 0.7rem !important;
    font-weight: 100 !important;
}


.body-container {
    &:empty {
        min-height: 90vh;
        position: relative;

        &:after {
            width: 100px;
            height: 50px; /* as the half of the width */
            border-top-left-radius: 100px;
            border-top-right-radius: 100px;
            border: 0.3rem solid var(--brand-one);
            border-bottom: 0;
            box-sizing: border-box;
            position: absolute;
            top: -20vh;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            content: '';
            animation: rotate-animation 0.6s linear infinite;
            transform-origin: 50% 100%;
        }
    }

    @include rotate-animation();
}