@import '../../global/styles/foundation/breakpoints';
@import '../../global/styles/foundation/typography';

.filterContainer {
    margin-top: 2rem;

    li {
        display: inline-block;
        font-family: $serif;
        font-family: var(--serif);
        font-size: 0.9rem;
        // font-style: italic;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        @media (max-width: $fablet) {
            display: block;
            padding: 0.3rem 0;
        }

        @media (min-width: $fablet) {
            &:not(:last-of-type):after {
                content: '  |  ';
                display: inline-block;
                white-space: pre;
            }
        }
        

        @media(min-width: $desktop-small) {
            &:hover, 
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .active {
        background: var(--brand-one);
        
        &:after {
            background: var(--brand-offwhite);
        }
    }
}