@import url("https://use.typekit.net/xzb5jtv.css");

// $serif: poynter-oldstyle-text, serif;
$serif: new-spirit, serif;
// $sans-serif: neuzeit-grotesk, sans-serif;
$sans-serif: proxima-nova, sans-serif;

h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.4;
}

p, span, div {
    letter-spacing: 0.3px;
    line-height: 1.4;
}