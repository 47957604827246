@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/theme';

.testimonialPanel {
    margin-top: 1rem;
    border-top: 1px solid var(--brand-black);
    border-bottom: 1px solid var(--brand-black);
}

.testimonialContentContainer {
    @include container(900px);
    text-align: center;
    padding: 10rem 4rem 8rem;

    @media (max-width: $fablet) {
        padding: 4rem 2rem;
    }
}

.sectionHeading {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-family: var(--sans-serif);
    font-weight: 400;
    letter-spacing: 5px;
    margin-bottom: 3rem;
}

.squiggle {
    max-width: 1.6rem;
    margin: 1.6rem 0 2rem;
}

.testimonialContent {
    font-family: var(--serif);
    font-size: 1.2rem;
    @media(max-width: $fablet) {
        font-size: 0.9rem;
    }
}

.author {
    font-family: var(--serif);
    font-size: 0.9rem;
}

.headline {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.button {
    @include btn-primary();
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .testimonialPanel {
        border-top: 0.6rem solid $brand-one;
        h3 {
            font-family: $sans-serif;
        }
        .author {
            font-family: $serif;
        }
    }
}