@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/theme';
@import '../../global/styles/foundation/breakpoints';


.workContainer {
    background: var(--brand-offwhite);
}

.headerPanel {
    @include page-header-panel();
    @media (max-width: $tablet) {
        padding: 6rem 2rem 4rem;
    }
}

.squiggle {
    @include squiggle();
    margin-bottom: 2rem;
}

.filters {
    margin-top: 3rem;

    @media (max-width: $fablet) {
        display: none;
    }
}
.categoryHeadline {
    margin-bottom: 0.5rem;
}

.filterWrapper:empty {
    height: 22px;
    background: $offwhite;
    display: block;
    animation: loading-flash 2s linear infinite;
    &:after {
        content: '. . . ';
    }
}

@keyframes loading-flash {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
  }