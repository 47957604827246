@import '../foundation/breakpoints';
@import '../foundation/typography';

@mixin container($max-width: 1400px) {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 2rem;
    @media (max-width: $desktop-large) {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    @media (max-width: $desktop-small) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @media (max-width: $fablet) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@mixin grid($cols:12) {
    display: grid;
    grid-template-columns: repeat($cols, 1fr);
}

@mixin transition($speed: 0.2s, $ease: ease-in-out) {
    transition: all $speed $ease;
}

@mixin psuedo-boiler() {
    position: absolute;
    content: '';
    display: block;
}

@mixin meta($top: 2rem, $right: 2rem) {
    position: absolute;
    text-transform: uppercase;
    right: $right;
    top: $top;
    writing-mode: vertical-rl;
    letter-spacing: 0.8rem;
    font-size: 0.8rem;
}

@mixin btn-primary($background: var(--brand-one), $hoverText: var(--brand-black), $normalText: var(--brand-black)) {
    display: inline-block;
    font-size: 1rem;
    // font-family: var(--serif);
    font-family: var(--sans-serif);
    padding: 1rem 2rem;
    position: relative;
    z-index: 0;
    border: 2px solid $background;
    text-transform: lowercase;
    font-weight: 600;
    color: $normalText;
    @include transition(0.15s, ease-out);

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0px;
        background: $background;
        @include transition(0.15s, ease-out);
        z-index: -10;
    }
    @media (min-width: $desktop-small) {
        &:hover {
            color: $hoverText;
            &:after {
                height: 100%;
            }
        }
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        font-family: $serif;

        &:after {
            background: black;
        }
        &:hover {
            color: white;
        }
    }
}

@mixin squiggle() {
    max-width: 3rem;
    margin: 3rem 0 4rem;
}

@mixin page-heading($decorative-period: true, $font-size: 5rem) {
    font-size: $font-size;
    line-height: 1;
    font-weight: 600;
    // font-family: $sans-serif;
    // font-family: var(--sans-serif);
    font-family: var(--serif);
    // text-transform: lowercase;

    @media (max-width: $tablet) {
        font-size: 2rem;
    }

    @if $decorative-period {
        &:after {
            content: '.';
            color: var(--brand-one);
        }
    }
}

@mixin page-header-panel() {
    @include container();
    padding-top: 10rem;
    padding-bottom: 10rem;
    
    h1,
    h2 {
        @include page-heading(false, clamp(22px, 3vw, 48px));
    }
}

@mixin loading-spinner-base() {
    position: relative;
    
    &:after {
        width: 100px;
        height: 50px; /* as the half of the width */
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        border: 0.3rem solid var(--brand-one);
        border-bottom: 0;
        box-sizing: border-box;
        position: absolute;
        top: -20vh;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        content: '';
        animation: rotate-animation 0.6s linear infinite;
        transform-origin: 50% 100%;
    }
}

@mixin rotate-animation() {
    @keyframes rotate-animation {
        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin label() {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-family: var(--sans-serif);
    font-weight: 400;
    letter-spacing: 3px;
    margin-bottom: 3rem;
}