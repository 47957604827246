@import '../../global/styles/utils/mixins';

.ctaPanel {
    background: var(--brand-offwhite);
    text-align: center;
    padding: 4rem;

    @media (max-width: $fablet) {
        padding: 2rem 1rem;
    }
}

.container {
    @include container();
}

.headline {
    font-size: 2rem;

    @media (max-width: $fablet) {
        font-size: 1.4rem;
        line-height: 1.4;
    }
    margin-bottom: 2rem;
}

.button {
    @include btn-primary(var(--brand-black), var(--brand-offwhite));
    margin-top: 2rem;

}