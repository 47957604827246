@import '../../global/styles/utils/mixins';
@import '../../global/styles/foundation/typography';

.gridItem {
    grid-column: span 4;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    @include transition();
    opacity: 0;

    img {
        width: 100%;
        max-width: 100%;
    }
}

.columns-4 {
    grid-column: span 3;
    @media (max-width: $tablet) {
        grid-column: 1/-1;
    }
}

.columns-3 {
    grid-column: span 4;
    @media (max-width: $tablet) {
        grid-column: 1/-1;
    }
}

.button {
    @include btn-primary();
    font-weight: 100;
    border-width: 1px;
    padding: 0.6rem 1.2rem;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    text-align: right;
    margin-top: 1.2rem;

    &:after {
        background: var(--hover);
        opacity: 0.05;
    }
}

.columns-2 {
    grid-column: span 6;

    .imageContainer {
        height: 650px;
    }

    .itemCaption {
        transform: translateY(75%);
        @media (max-width: $desktop-small) { transform: translateY(72%); }
    }

}

.columns-1 {
    grid-column: 1/-1;
}

.gridItem, .columns-4, .columns-3 {
    @media (max-width: $desktop-large) {
        grid-column: span 6;
    }
    @media (max-width: $tablet) {
        grid-column: 1/-1;
    }
}

.imageContainer {
    overflow: hidden;
    height: 500px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(19,17,21,0.8) 0%, rgba(19,17,21,0.7) 100%);
        content: '';
    }
}

.itemCaption {
    position: absolute;
    width: 85%;
    height: 100%;
    bottom: 9rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: var(--brand-offwhite);
    transform: translateY(75%);
    @media (max-width: 1800px) { transform: translateY(70%); }
    @media (max-width: $desktop-small) { transform: translateY(66%); }
    @media (max-width: $fablet) { transform: translateY(68%); }
    @media (max-width: $mobile) { transform: translateY(65%); }
    z-index: 10;
    transition: transform 0.2s ease;

    p {
        @include transition();
    }
}

.itemLogo {
    margin-bottom: 1rem;

    img {
        max-width: 100%;
        width: 220px;
        height: 180px;
        object-fit: contain;
        object-position: left bottom;
    }

    .hoverShow {
        display: none;
    }

}

.itemTitle {
    display: none;
}

.itemTitleFallback {
    font-size: clamp(20px, 1.8vw, 26px);
    @media (max-width: $fablet) { font-size: 1.8rem; }
    font-weight: 600;
    font-family: var(--serif);
    font-weight: 400;
    margin-top: 9rem;
}

.itemCategories {
    letter-spacing: 0.5px;
    max-width: 85%;
}

.itemSubtext {
    opacity: 0;
    font-size: 14px;
}

.hoverActive {
    .hoverHide {
        display: none;
    }

    .hoverShow {
        display: block;
    }

    @media (min-width: $desktop-small) {
        cursor: pointer;

        .itemHoverBackground {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 0;
            @include transition();
            cursor: pointer;
        }

        .itemCaption {
            transform: translateY(36%);
            cursor: pointer;

            &.darkHover {
                @media(min-width: $desktop-small) {
                    color: var(--brand-black);
                }
            }
        }

        .itemLogo {
            margin-bottom: 2rem;
        }
    
        .itemTitle {
            transform: translateY(-1rem);
        }
    
        .itemCategories {
            transform: translateY(-0.5rem)
        }
    
        .itemSubtext {
            opacity: 1;
        }

        .button {
            opacity: 1;
            cursor: pointer;
        }
    }
}
.unmounted { 
    opacity: 0;
    
}

.mounted {
    opacity: 1;
}

// ie fix
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .itemCaption {
        color: var(--brand-offwhite);
    }
    .itemSubtext,
    .itemTitle {
        font-family: $serif;
    }
    .hoverActive {
        .itemCaption {
            &.darkHover {
                color: var(--brand-black);
            }
        }
    }
    .imageContainer {
        img {
            max-width: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -45%);
            width: auto;
            height: 225%;
        }
    }
}
